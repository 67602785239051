<template>
  <b-modal
    id="export-modal"
    v-model="$show"
    centered
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
    hide-header-close
    v-bind="{ ...$attrs, ...$props }"
    title="ดาวน์โหลดรายงาน"
  >
    <loading message="กรุณารอสักครู่ ระบบกำลังดาวน์โหลดรายงาน"></loading>
  </b-modal>
</template>

<script>
import Loading from "@/components/misc/Loading";

export default {
  props: {
    show: Boolean,
  },

  components: {
    Loading,
  },

  model: {
    prop: "show",
    event: "change",
  },

  computed: {
    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("change", v);

        return this;
      },
    },
  },
};
</script>
