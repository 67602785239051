import XLSX from "xlsx";

function colName(n) {
    var ordA = 'a'.charCodeAt(0);
    var ordZ = 'z'.charCodeAt(0);
    var len = ordZ - ordA + 1;

    var s = "";
    while (n >= 0) {
        s = String.fromCharCode(n % len + ordA) + s;
        n = Math.floor(n / len) - 1;
    }
    return s;
}

function colIndex(str) {
    str = str.toUpperCase();
    let out = 0, len = str.length;
    for (let pos = 0; pos < len; pos++) {
        out += (str.charCodeAt(pos) - 64) * Math.pow(26, len - pos - 1);
    }
    return out;
}

const alphabets = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
];

const reportMixin = {
    methods: {
        $_reportMixin_setWidthColumn(ws, header, data, fixedLengthHeader = {}) {
            // console.log(header);
            let objectMaxLength = header.map((h) => fixedLengthHeader[h] || `${h}`.length);

            for (let i = 0; i < data.length; i++) {
                header.forEach((key, j) => {
                    if (!fixedLengthHeader[key]) {
                        const valueString = `${data[i][key]}`;

                        objectMaxLength[j] =
                            objectMaxLength[j] >= valueString.length
                                ? objectMaxLength[j]
                                : valueString.length;
                    }
                });

                // let value = Object.values(data[i]);

                // for (let j = 0; j < value.length; j++) {
                //     const valueString = `${value[j]}`;

                //     objectMaxLength[j] =
                //         objectMaxLength[j] >= valueString.length
                //             ? objectMaxLength[j]
                //             : valueString.length;
                // }
            }

            // console.log(objectMaxLength);

            this.$set(
                ws,
                "!cols",
                objectMaxLength.map((obj) => ({ width: obj }))
            );

            return ws;
        },

        $_reportMixin_mergeCellBy(ws, colKey, { orderCol = "A", headerRow = 1, startOrder = 1, mergedCol = {} } = {}) {
            let colKeyRef, colKeyIndex,
                mergedColRefs = {},
                mergedColIndice = {};

            for (let index = 0; index < alphabets.length; index++) {
                const alphabet = alphabets[index];

                const colHeader = ws[`${alphabet}${headerRow}`];

                if (colHeader) {
                    if (colHeader && colHeader.v === colKey) {
                        colKeyRef = alphabet;
                        colKeyIndex = index;
                    } else if (mergedCol[colHeader.v]) {
                        mergedColRefs[colHeader.v] = alphabet;
                        mergedColIndice[colHeader.v] = index;
                    }

                }
            }

            if (colKeyRef) {
                const keyIndices = {};
                const keyOrders = {};
                let order = startOrder;
                const sheetRange = ws["!ref"];
                const lastCell = sheetRange.split(":")[1];
                const lastRow = lastCell.match(/\d+/)[0];

                for (let index = 2; index <= lastRow; index++) {
                    const cell = ws[`${colKeyRef}${index}`];

                    const keyValue = cell.v;

                    if (!keyIndices[keyValue]) {
                        keyIndices[keyValue] = [];
                        keyOrders[order] = keyValue;
                        order += 1;
                    }

                    keyIndices[keyValue].push(index);
                }

                const mergeRanges = [];

                for (const keyOrder in keyOrders) {
                    if (Object.hasOwnProperty.call(keyOrders, keyOrder)) {
                        const keyValue = keyOrders[keyOrder];
                        const keyIndice = keyIndices[keyValue].sort((a, b) => a - b);
                        const startRow = keyIndice[0];
                        const endRow = keyIndice[keyIndice.length - 1];

                        // console.log(
                        //     keyOrder,
                        //     keyValue,
                        //     keyIndice,
                        //     startRow,
                        //     endRow,
                        //     colKeyIndex,
                        //     ws[`${orderCol}${startRow}`],
                        //     mergedCol,
                        //     mergedColRefs,
                        //     mergedColIndice
                        // );

                        this.$set(ws[`${colKeyRef}${startRow}`], "v", keyValue);

                        mergeRanges.push({
                            s: { r: startRow - 1, c: colKeyIndex },
                            e: { r: endRow - 1, c: colKeyIndex },
                        });

                        this.$set(ws[`${orderCol}${startRow}`], "v", keyOrder);

                        mergeRanges.push({
                            s: { r: startRow - 1, c: 0 },
                            e: { r: endRow - 1, c: 0 },
                        });

                        Object.entries(mergedCol).forEach(entry => {
                            const col = entry[0];
                            const fn = entry[1];
                            const mergedColRef = mergedColRefs[col];
                            const mergedColIndex = mergedColIndice[col];

                            const records = [];

                            for (let row = startRow; row <= endRow; row++) {
                                const cell = ws[`${mergedColRef}${row}`];

                                records.push(cell);
                            }

                            const keyValue = fn(records);

                            this.$set(ws[`${mergedColRef}${startRow}`], "v", keyValue);

                            mergeRanges.push({
                                s: { r: startRow - 1, c: mergedColIndex },
                                e: { r: endRow - 1, c: mergedColIndex },
                            });
                        })
                    }
                }

                if (mergeRanges.length) {
                    const originalMergeRanges = ws["!merges"] || [];

                    this.$set(ws, "!merges", [...originalMergeRanges, ...mergeRanges]);
                }
            }

            return ws;
        },

        $_reportMixin_setTextWrap(ws, colKey, colIndex, { headerRow = 1 } = {}) {
            let colKeyRef;

            const alphabet = `${colName(colIndex)}`.toUpperCase();

            const colHeader = ws[`${alphabet}${headerRow}`];

            if (colHeader) {
                if (colHeader && colHeader.v === colKey) {
                    colKeyRef = alphabet;
                    // colKeyIndex = index;
                }
                // else if (mergedCol[colHeader.v]) {
                //     mergedColRefs[colHeader.v] = alphabet;
                //     mergedColIndice[colHeader.v] = index;
                // }

            }

            if (colKeyRef) {
                // const keyIndices = {};
                // const keyOrders = {};
                // let order = 1;
                const sheetRange = ws["!ref"];
                const lastCell = sheetRange.split(":")[1];
                const lastRow = lastCell.match(/\d+/)[0];

                for (let index = headerRow; index <= lastRow; index++) {
                    this.$set(ws[`${colKeyRef}${index}`], "s", {
                        alignment: {
                            wrapText: true
                        },
                    });

                    // const keyValue = cell.v;

                    // if (!keyIndices[keyValue]) {
                    //     keyIndices[keyValue] = [];
                    //     keyOrders[order] = keyValue;
                    //     order += 1;
                    // }

                    // keyIndices[keyValue].push(index);
                }

                // const mergeRanges = [];

                // for (const keyOrder in keyOrders) {
                //     if (Object.hasOwnProperty.call(keyOrders, keyOrder)) {
                //         const keyValue = keyOrders[keyOrder];
                //         const keyIndice = keyIndices[keyValue].sort((a, b) => a - b);
                //         const startRow = keyIndice[0];
                //         const endRow = keyIndice[keyIndice.length - 1];

                //         // console.log(
                //         //     keyOrder,
                //         //     keyValue,
                //         //     keyIndice,
                //         //     startRow,
                //         //     endRow,
                //         //     colKeyIndex,
                //         //     ws[`${orderCol}${startRow}`],
                //         //     mergedCol,
                //         //     mergedColRefs,
                //         //     mergedColIndice
                //         // );

                //         // this.$set(ws[`${colKeyRef}${startRow}`], "v", keyValue);

                //         // mergeRanges.push({
                //         //     s: { r: startRow - 1, c: colKeyIndex },
                //         //     e: { r: endRow - 1, c: colKeyIndex },
                //         // });

                //         // this.$set(ws[`${orderCol}${startRow}`], "v", keyOrder);

                //         // mergeRanges.push({
                //         //     s: { r: startRow - 1, c: 0 },
                //         //     e: { r: endRow - 1, c: 0 },
                //         // });
                //     }
                // }

                // if (mergeRanges.length) {
                //     this.$set(ws, "!merges", mergeRanges);
                // }
            }

            return ws;
        },

        $_reportMixin_setCellValue(
            ws,
            cellValue,
            { startRow, startCol, style = null } = {}
        ) {
            const alphabet = `${colName(startCol - 1)}`.toUpperCase();

            // console.log(ws, alphabet, startRow, ws[`${alphabet}${startRow}`], cellValue);

            if (ws[`${alphabet}${startRow}`]) {
                this.$set(ws[`${alphabet}${startRow}`], "v", cellValue);

                if (style) {
                    this.$set(ws[`${alphabet}${startRow}`], "s", {
                        ...style
                    });
                }

                // console.log(ws[`${alphabet}${startRow}`]);
            }

            return ws;
        },

        $_reportMixin_setMergeCell(
            ws,
            cellValue,
            { startRow, endRow, startCol, endCol, style = null } = {}
        ) {
            const alphabet = `${colName(startCol - 1)}`.toUpperCase();

            // console.log(ws, alphabet, startRow, ws[`${alphabet}${startRow}`], cellValue);

            if (ws[`${alphabet}${startRow}`]) {
                this.$set(ws[`${alphabet}${startRow}`], "v", cellValue);

                if (style) {
                    this.$set(ws[`${alphabet}${startRow}`], "s", {
                        ...style
                    });
                }
                // console.log(ws[`${alphabet}${startRow}`]);
                const originalMergeRanges = ws["!merges"] || [];

                this.$set(
                    ws,
                    "!merges",
                    [
                        ...originalMergeRanges,
                        {
                            s: { r: startRow - 1, c: startCol - 1 },
                            e: { r: endRow - 1, c: endCol - 1 },
                        }
                    ]
                );
            }

            return ws;
        },

        $_reportMixin_addDate(
            ws,
            {
                startDate = null,
                endDate = null,
                label = "ข้อมูลวันที่",
                labelStartDate = "เริ่ม",
                labelEndDate = "สิ้นสุด",
                row = 1,
                offset = 1
            } = {}
        ) {
            const sheetRange = ws["!ref"];
            const cells = sheetRange.split(":");
            const lastCell = cells[1];

            let lastCol = lastCell.match(/[A-Z]+/)[0];

            const lastColLength = lastCol.length;

            const nextColLabel = String.fromCharCode(
                lastCol.charCodeAt(lastColLength - 1) + 1 + offset
            );

            const nextColLabelStartDate = String.fromCharCode(
                lastCol.charCodeAt(lastColLength - 1) + 2 + offset
            );

            const nextColLabelEndDate = String.fromCharCode(
                lastCol.charCodeAt(lastColLength - 1) + 3 + offset
            );

            // const nextColValue = String.fromCharCode(
            //     lastCol.charCodeAt(lastColLength - 1) + 2 + offset
            // );

            const lastColLabel = lastCol.substring(0, lastColLength - 1) + nextColLabel;
            const lastColLabelStartDate = lastCol.substring(0, lastColLength - 1) + nextColLabelStartDate;
            const lastColLabelEndDate = lastCol.substring(0, lastColLength - 1) + nextColLabelEndDate;
            // lastColValue = lastCol.substring(0, lastColLength - 1) + nextColValue;

            XLSX.utils.sheet_add_aoa(ws, [[label]], { origin: `${lastColLabel}${row}` });
            XLSX.utils.sheet_add_aoa(ws, [[labelStartDate]], { origin: `${lastColLabelStartDate}${row}` });
            XLSX.utils.sheet_add_aoa(ws, [[labelEndDate]], { origin: `${lastColLabelEndDate}${row}` });
            // this.$set(ws[`${lastColLabel}${row}`], "v", label);

            if (!startDate) {
                startDate = "-";
            }

            if (!endDate) {
                endDate = "-";
            }

            XLSX.utils.sheet_add_aoa(ws, [[startDate]], { origin: `${lastColLabelStartDate}${row + 1}` });
            XLSX.utils.sheet_add_aoa(ws, [[endDate]], { origin: `${lastColLabelEndDate}${row + 1}` });

            return ws;
        },

        $_reportMixin_addRating(
            ws,
            {
                rating = {},
                row = 1,
                offset = 1
            } = {}
        ) {
            const sheetRange = ws["!ref"];
            const cells = sheetRange.split(":");
            const lastCell = cells[1];

            let lastCol = lastCell.match(/[A-Z]+/)[0];

            const lastColIndex = colIndex(lastCol);

            // const lastColLength = lastCol.length;

            // console.log(lastColLength);

            const lastColLabel = colName(
                lastColIndex + 1 + offset
            ).toUpperCase();

            const middlelastColLabel = colName(
                lastColIndex + 1 + offset + 1
            ).toUpperCase();

            const endlastColLabel = colName(
                lastColIndex + 1 + offset + 3
            ).toUpperCase();

            // const nextColLabel = String.fromCharCode(
            //     lastCol.charCodeAt(lastColLength - 1) + 1 + offset
            // );

            // const middleColLabel = String.fromCharCode(
            //     lastCol.charCodeAt(lastColLength - 1) + 1 + offset + 1
            // );

            // const endnextColLabel = String.fromCharCode(
            //     lastCol.charCodeAt(lastColLength - 1) + 1 + offset + 3
            // );

            // console.log(lastCol, lastColLabel, middlelastColLabel, endlastColLabel);

            // const nextColLabelStartDate = String.fromCharCode(
            //     lastCol.charCodeAt(lastColLength - 1) + 2 + offset
            // );

            // const nextColLabelEndDate = String.fromCharCode(
            //     lastCol.charCodeAt(lastColLength - 1) + 3 + offset
            // );

            // const nextColValue = String.fromCharCode(
            //     lastCol.charCodeAt(lastColLength - 1) + 2 + offset
            // );

            // const lastColLabel = lastCol.substring(0, lastColLength - 1) + nextColLabel;
            // const middlelastColLabel = lastCol.substring(0, lastColLength - 1) + middleColLabel;
            // const endlastColLabel = lastCol.substring(0, lastColLength - 1) + endnextColLabel;

            // const lastColLabelStartDate = lastCol.substring(0, lastColLength - 1) + nextColLabelStartDate;
            // const lastColLabelEndDate = lastCol.substring(0, lastColLength - 1) + nextColLabelEndDate;
            // lastColValue = lastCol.substring(0, lastColLength - 1) + nextColValue;

            XLSX.utils.sheet_add_aoa(ws, [["การประเมินการเรียนการสอนตามสภาพจริง"]], { origin: `${lastColLabel}${row}` });

            const originalMergeRanges = ws["!merges"] || [];

            this.$set(
                ws,
                "!merges",
                [
                    ...originalMergeRanges,
                    {
                        s: { r: row - 1, c: colIndex(lastColLabel) - 1 },
                        e: { r: row - 1, c: colIndex(endlastColLabel) - 1 },
                    }
                ]
            );

            let step = 1;

            for (const label in rating) {
                if (Object.hasOwnProperty.call(rating, label)) {
                    const value = rating[label];

                    XLSX.utils.sheet_add_aoa(ws, [[label]], { origin: `${lastColLabel}${row + step}` });
                    XLSX.utils.sheet_add_aoa(ws, [['=']], { origin: `${middlelastColLabel}${row + step}` });
                    XLSX.utils.sheet_add_aoa(ws, [[value]], { origin: `${endlastColLabel}${row + step}` });

                    step += 1;
                }
            }

            // XLSX.utils.sheet_add_aoa(ws, [[labelStartDate]], { origin: `${lastColLabelStartDate}${row}` });
            // XLSX.utils.sheet_add_aoa(ws, [[labelEndDate]], { origin: `${lastColLabelEndDate}${row}` });
            // // this.$set(ws[`${lastColLabel}${row}`], "v", label);

            // if (!startDate) {
            //     startDate = "-";
            // }

            // if (!endDate) {
            //     endDate = "-";
            // }

            // XLSX.utils.sheet_add_aoa(ws, [[startDate]], { origin: `${lastColLabelStartDate}${row + 1}` });
            // XLSX.utils.sheet_add_aoa(ws, [[endDate]], { origin: `${lastColLabelEndDate}${row + 1}` });

            return ws;
        }
    }
};

export default reportMixin;
